<template>
  <div id="app">
    <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import okxWeb3 from '@okwallet/extension';
import okWeb3 from '@okwallet/extension';
// import Evm from '@okwallet/extension/src/chain/Evm';
import VConsole from 'vconsole';
import {isOkxApp} from "@okwallet/extension/src/utils";


export default {
  name: 'App',
  components: {
    HelloWorld
  },
  created() {
    new VConsole();
    console.log(1)
    console.log(okxWeb3);
    console.log("isOkxApp = ", isOkxApp())

    // const provider = getProvider();
    // const currentChainId =  Evm.getChainId(provider).then(function (res) {
    //   console.log("currentChainId1 = ",res)
    // })
    // console.log("currentChainId2 = ",currentChainId)

    window.okxwallet.request({method: 'eth_chainId'}).then(function (res) {
      console.log("eth_chainId = ", res)
      if (res != 0x89) {
        alert("切换网络到polygon")
        window.okxwallet.request({
          method: 'wallet_switchEthereumChain',
          params: [{
            chainId: "0x89"
          }]
        }).then(function (res) {
          console.log("res1=", res)
        }).cache(function (err) {
          console.log("err = ", err)
        })
      }
    })


    // okWeb3.getAddress(okWeb3.POLYGON)
    //     .then((addr) => {
    //       console.log("addr", addr);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });

    // add
    okWeb3.addListener('connect', (isConnected) => {
      console.log(isConnected); // boolean
    });

    okWeb3.addListener('disconnect', () => {
    });

    okWeb3.addListener('connectWallet', (wallet) => {
      console.log("connectWallet", wallet);
    });

    okWeb3.addListener('walletChanged', (wallet) => {
      console.log("walletChanged", wallet);
    });

    okWeb3.addListener('accountChanged', (wallet) => {
      console.log("accountChanged", wallet);
    });

    okWeb3.addListener('networkChanged', (chainId) => {
      console.log("networkChanged", chainId);
    });

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
